import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { CloseIcon, PauseIcon, PlayIcon } from "../icons";

function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}

const ExternalVideoPlayer: React.FC<{
  externalVideoUrl: string;
  showExternalPlayer: boolean;
  onClose: () => void;
}> = ({ externalVideoUrl, showExternalPlayer, onClose }) => {
  const [touchScreen, setTouchScreen] = useState(true);
  const [externalPaused, setExternalPaused] = useState(false);
  const handleExternalPause = useCallback(() => setExternalPaused(true), []);
  const handleExternalResume = useCallback(() => setExternalPaused(false), []);
  const handleExternalClose = useCallback(() => {
    onClose();
  }, [onClose]);
  useEffect(() => setExternalPaused(!showExternalPlayer), [showExternalPlayer]);
  useEffect(() => {
    setTouchScreen(isTouchDevice());
  }, []);
  return (
    <div
      className={classNames(
        showExternalPlayer
          ? "opacity-100 z-50 w-screen h-screen"
          : "opacity-0 -z-1 w-0 h-0",
        "transition-all duration-1000",
        "fixed 2xl:top-0 xl:-top-10px lg:-top-20px left-0 bg-grey top-0",
        "flex flex-col items-center justify-start"
      )}
    >
      <div className="border-2 border-dark-blue w-full">
        <div className="relative aspect-w-16 aspect-h-9">
          {showExternalPlayer && (
            <ReactPlayer
              playing={!externalPaused}
              controls={touchScreen}
              playsinline={true}
              url={externalVideoUrl}
              width="100%"
              height="100%"
              style={{
                maxHeight: "88vh",
                maxWidth: "100vw",
                height: "100%",
                width: "100%",
              }}
            />
          )}
          {!touchScreen && (
            <div className="w-full h-full lg:opacity-0 lg:hover:opacity-100 flex justify-center items-center">
              {externalPaused ? (
                <button className="text-lazer" onClick={handleExternalResume}>
                  <PlayIcon className="h-100px w-100px" />
                </button>
              ) : (
                <button className="text-lazer" onClick={handleExternalPause}>
                  <PauseIcon className="h-100px w-100px" />
                </button>
              )}
            </div>
          )}
        </div>
        <button
          className="absolute top-20px right-20px"
          onClick={handleExternalClose}
        >
          <CloseIcon className="h-50px w-50px" />
        </button>
      </div>
    </div>
  );
};

export default ExternalVideoPlayer;
