import React from "react";

interface Props {
  className?: string;
}

export const CloseIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      className={className}
    >
      <g
        id="Groupe_1923"
        data-name="Groupe 1923"
        transform="translate(-831 -457)"
      >
        <circle
          id="Ellipse_462"
          data-name="Ellipse 462"
          cx="13.5"
          cy="13.5"
          r="13.5"
          transform="translate(831 457)"
          fill="#9c3179"
        />
        <g
          id="Groupe_1925"
          data-name="Groupe 1925"
          transform="translate(36.087 4.242)"
        >
          <g
            id="Groupe_1242"
            data-name="Groupe 1242"
            transform="translate(813.106 472.738) rotate(-135)"
          >
            <path
              id="Tracé_100"
              data-name="Tracé 100"
              d="M2.238,1.131A1.119,1.119,0,1,1,1.119,0,1.12,1.12,0,0,1,2.238,1.131Z"
              transform="translate(0 0)"
              fill="rgba(254,254,254,0.97)"
            />
            <path
              id="Tracé_101"
              data-name="Tracé 101"
              d="M2.238,1.131A1.119,1.119,0,1,1,1.119,0,1.119,1.119,0,0,1,2.238,1.131Z"
              transform="translate(3.037 0)"
              fill="rgba(254,254,254,0.97)"
            />
            <path
              id="Tracé_102"
              data-name="Tracé 102"
              d="M2.238,1.131A1.119,1.119,0,1,1,1.119,0,1.12,1.12,0,0,1,2.238,1.131Z"
              transform="translate(6.06 0)"
              fill="rgba(254,254,254,0.97)"
            />
            <circle
              id="Ellipse_42"
              data-name="Ellipse 42"
              cx="1.119"
              cy="1.119"
              r="1.119"
              transform="translate(6.058 3.046) rotate(-0.107)"
              fill="rgba(254,254,254,0.97)"
            />
            <path
              id="Tracé_104"
              data-name="Tracé 104"
              d="M2.238,1.119A1.119,1.119,0,1,1,1.119,0,1.119,1.119,0,0,1,2.238,1.119Z"
              transform="translate(6.06 6.062)"
              fill="rgba(254,254,254,0.97)"
            />
          </g>
          <g
            id="Groupe_1924"
            data-name="Groupe 1924"
            transform="translate(804.292 461) rotate(45)"
          >
            <path
              id="Tracé_100-2"
              data-name="Tracé 100"
              d="M2.238,1.131A1.119,1.119,0,1,1,1.119,0,1.12,1.12,0,0,1,2.238,1.131Z"
              transform="translate(0 0)"
              fill="rgba(254,254,254,0.97)"
            />
            <path
              id="Tracé_101-2"
              data-name="Tracé 101"
              d="M2.238,1.131A1.119,1.119,0,1,1,1.119,0,1.119,1.119,0,0,1,2.238,1.131Z"
              transform="translate(3.037 0)"
              fill="rgba(254,254,254,0.97)"
            />
            <circle
              id="Ellipse_42-2"
              data-name="Ellipse 42"
              cx="1.119"
              cy="1.119"
              r="1.119"
              transform="translate(6.058 3.046) rotate(-0.107)"
              fill="rgba(254,254,254,0.97)"
            />
            <path
              id="Tracé_104-2"
              data-name="Tracé 104"
              d="M2.238,1.119A1.119,1.119,0,1,1,1.119,0,1.119,1.119,0,0,1,2.238,1.119Z"
              transform="translate(6.06 6.062)"
              fill="rgba(254,254,254,0.97)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
