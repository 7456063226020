import React from "react";

interface Props {
  className?: string;
}

export const PlayIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="79"
      viewBox="0 0 79 79"
      className={className}
    >
      <g
        id="Groupe_1926"
        data-name="Groupe 1926"
        transform="translate(14099 15333)"
      >
        <g
          id="Groupe_1919"
          data-name="Groupe 1919"
          transform="translate(-14660 -18422)"
        >
          <circle
            id="Ellipse_436"
            data-name="Ellipse 436"
            cx="39.5"
            cy="39.5"
            r="39.5"
            transform="translate(561 3089)"
            fill="#fff"
          />
          <g
            id="Groupe_1260"
            data-name="Groupe 1260"
            transform="translate(596.574 3099.158) rotate(45)"
          >
            <path
              id="Tracé_100"
              data-name="Tracé 100"
              d="M11.125,5.621A5.563,5.563,0,1,1,5.562,0,5.565,5.565,0,0,1,11.125,5.621Z"
              fill="#9c3179"
            />
            <path
              id="Tracé_101"
              data-name="Tracé 101"
              d="M11.124,5.621A5.562,5.562,0,1,1,5.562,0,5.564,5.564,0,0,1,11.124,5.621Z"
              transform="translate(14.85 0.245)"
              fill="#9c3179"
            />
            <path
              id="Tracé_102"
              data-name="Tracé 102"
              d="M11.125,5.621A5.563,5.563,0,1,1,5.562,0,5.565,5.565,0,0,1,11.125,5.621Z"
              transform="translate(29.879 0.245)"
              fill="#9c3179"
            />
            <path
              id="Tracé_1022"
              data-name="Tracé 1022"
              d="M11.125,5.621A5.563,5.563,0,1,1,5.562,0,5.565,5.565,0,0,1,11.125,5.621Z"
              transform="translate(15.062 15.062)"
              fill="#9c3179"
            />
            <circle
              id="Ellipse_42"
              data-name="Ellipse 42"
              cx="5.562"
              cy="5.562"
              r="5.562"
              transform="translate(29.871 15.385) rotate(-0.107)"
              fill="#9c3179"
            />
            <path
              id="Tracé_104"
              data-name="Tracé 104"
              d="M11.125,5.564A5.562,5.562,0,1,1,5.562,0,5.565,5.565,0,0,1,11.125,5.564Z"
              transform="translate(29.879 30.377)"
              fill="#9c3179"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
