import React from "react";

interface Props {
  className?: string;
}

export const PauseIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="79"
      viewBox="0 0 79 79"
      className={className}
    >
      <g
        id="Groupe_1927"
        data-name="Groupe 1927"
        transform="translate(13899 15386)"
      >
        <circle
          id="Ellipse_605"
          data-name="Ellipse 605"
          cx="39.5"
          cy="39.5"
          r="39.5"
          transform="translate(-13899 -15386)"
          fill="#fff"
        />
        <g
          id="Groupe_1922"
          data-name="Groupe 1922"
          transform="translate(0.276 0.173)"
        >
          <g
            id="Groupe_1920"
            data-name="Groupe 1920"
            transform="translate(-13865.846 -15367.174) rotate(90)"
          >
            <path
              id="Tracé_100"
              data-name="Tracé 100"
              d="M11.125,5.621A5.563,5.563,0,1,1,5.562,0,5.565,5.565,0,0,1,11.125,5.621Z"
              fill="#9c3179"
            />
            <path
              id="Tracé_101"
              data-name="Tracé 101"
              d="M11.124,5.621A5.562,5.562,0,1,1,5.562,0,5.564,5.564,0,0,1,11.124,5.621Z"
              transform="translate(14.85 0.245)"
              fill="#9c3179"
            />
            <path
              id="Tracé_102"
              data-name="Tracé 102"
              d="M11.125,5.621A5.563,5.563,0,1,1,5.562,0,5.565,5.565,0,0,1,11.125,5.621Z"
              transform="translate(29.879 0.245)"
              fill="#9c3179"
            />
          </g>
          <g
            id="Groupe_1921"
            data-name="Groupe 1921"
            transform="translate(-13841.846 -15367.174) rotate(90)"
          >
            <path
              id="Tracé_100-2"
              data-name="Tracé 100"
              d="M11.125,5.621A5.563,5.563,0,1,1,5.562,0,5.565,5.565,0,0,1,11.125,5.621Z"
              fill="#9c3179"
            />
            <path
              id="Tracé_101-2"
              data-name="Tracé 101"
              d="M11.124,5.621A5.562,5.562,0,1,1,5.562,0,5.564,5.564,0,0,1,11.124,5.621Z"
              transform="translate(14.85 0.245)"
              fill="#9c3179"
            />
            <path
              id="Tracé_102-2"
              data-name="Tracé 102"
              d="M11.125,5.621A5.563,5.563,0,1,1,5.562,0,5.565,5.565,0,0,1,11.125,5.621Z"
              transform="translate(29.879 0.245)"
              fill="#9c3179"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
